import React from "react";
import {CookieBanner, Footer, Header, Loading} from "../../components";

import {getLoginStatus} from "../../utility/authenticate";
import {useCmsData} from "../../utility/CmsDataProvider";
import {getCmsLanguage, getLanguage} from "../../utility/language";

import {useLoginState} from "../../api/login/loginState";
import '../../App.css';
import style from './style.module.css';

export default function Imprint() {

    const {data, loading, error} = useCmsData()
    const {
        state: {loggedIn, token: {token} = {}},
    } = useLoginState()

    if (loading) {
        return <Loading></Loading>
    } else if (error) {
        return <h2>Error while loading CMS...</h2>
    }

    const footer = data?.asModel.reffromreflist('footer', "6ztqv4t7gksfukhvryugob3xewtxpfvfx2bme", true)

    data?.setLanguage(getCmsLanguage())
    footer?.setLanguage(getCmsLanguage())

    const imprintTitle = footer?.asModel.str('imprintTitle');
    const imprintText = footer?.asModel.str('imprintText');
    console.log(imprintTitle);
    console.log(imprintText);

    return (
        <div>
            <CookieBanner/>
            <Header solid/>

            <div className={style.container}>
                <h1 className={style.title}>
                    {imprintTitle}
                </h1>
                <pre className={style.paragraph}>
                    {imprintText}
                </pre>
            </div>

            <Footer/>
        </div>
    )
}